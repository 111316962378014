@import "_mixins";

.app-contact-wrapper {

	@include golden-top();
	padding: 20px 20px 80px;
	
	> .container {
		max-width: 680px;
	}
	
	h2 {
		text-transform: uppercase;
		margin: 20px 0px 30px;
	}

	.form-control {
		border-radius: 0px;
	}

	input[type=submit] {
		background-color: $green-color;
		color: $white-color;
		padding: 10px 20px;
		border-radius: 0px;
		border: 0;
		outline: 0;
		text-transform: uppercase;
		&:hover {
			background-color: $yellow-color;
			color: $black-color;
		}
	}
}