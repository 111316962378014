@import "_vars";
@import "_mixins";

.app-table-wrapper {

	width: 100%;
	padding: 20px 40px;

	.entities-table {
		width: 100%;
		//almost all cells hidden by default on mobile
		td, th {
			display: none;
		}
		th span {
			position: relative;
		}
		th:first-child, td:first-child {
			display: table-cell;
			width: 80%;
			text-align: left;
		}
		td, th, td span, th span {
			text-align: right;
		}
		td, th {
			padding: 10px 5px;
			span {
				padding: 5px;
			}
		}
		td {
			border: 0px;
			border-top: 1px solid black;
			border-bottom: 1px solid black;
		}
		th:nth-child(2),
		td:nth-child(2) {
			display: table-cell;
			width: 20%;
		}

		@media( min-width:$screen-sm-min ) {
			th, td {
				display: table-cell;
			}
			th:first-child, td:first-child {
				width: 30%;
			}
			th:nth-child(2),
			td:nth-child(2) {
				width: 10%;
			}
			th:nth-child(3),
			td:nth-child(3) {
				width: 30%;
			}
			th:nth-child(4),
			td:nth-child(4),
			th:nth-child(5),
			td:nth-child(5) {
				width: 5%;
			}
			th:last-child,
			td:last-child {
				width: 20%;
			}
		}

		th:not(:last-child) {
			cursor: pointer;
			&.sorting-asc span:before,
			&:hover span:before {
				@include sort-arrow();
			}
			&.sorting-desc span:before {
				@include desc-sort-arrow();
			}
		}
		th:first-child {
			&.sorting-asc span:after,
			&:hover span:before {
				left: inherit;
				right: -7px;
			}
			&.sorting-desc span:before {
				left: inherit;
				right: -7px;
			}
		}

		td > .barometer {
			width: 90%;
			margin: 0 auto;
		}

	}

	.form-group {
		width: 340px;

		label {
			font-weight: normal;
		}
		select {
			margin-left: 5px;
		}
	}

	.index-group {
		width: 500px;

		label {
			margin-right: 5px;
			margin-left: 5px;
		}
		input {
			margin-right: 3px;
		}
	}

	.form-group, 
	.form-control {
		display: inline-block;
		width: 180px;
		margin-right: 5px;
	}

}

/** data-row **/
.data-row {
	&:hover {
		background-color: #000000;
		cursor: pointer;
		color: #ffffff;
	}
}
	