@import "_vars";

.app-footer {
	overflow: auto;
	position: relative;
	margin-top: 30px;
	
	color: $black-color;
	background-color: $white-color;

	> div {
		position: relative;
	}

	a {
		color: $black-color;
	}

	.footer-top {
		background-color: $yellow-color;
		@media( min-width:$screen-sm-min ) {

			&:before {
				content: " ";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 35%;
				height: 100%;
				background: url( "../imgs/footer-stripe.png" ) $green-color top right no-repeat;
				background-size: contain;
			}

		}
	}

	.footer-navigation-left {
		padding: 15px 0;
		text-align: center;
		a:hover {
			text-decoration: none;
		}
		h3 {
			margin: 0;
			color: $white-color;
			font-size: 18px;
		}
		@media( min-width:$screen-sm-min ) {
			text-align: left
		}
	}
	.footer-navigation-right {}

	.footer-navigation {
		margin: 0 auto;
		text-align: center;
    	@media( min-width:$screen-sm-min ) {
    		float: right;
			margin: 0;
		}
		padding: 15px 0;
		list-style-type: none;

		li {
			padding: 5px 20px;
			@media( min-width:$screen-sm-min ) {
				float: left;
				padding: 0px 20px;
				border-right: 1px solid $black-color;
				&:last-child {
					padding-right: 0;
				}
			}
			&:last-child {
				border-right: 0;
			}
			a:hover {
				text-decoration: underline;
			}
		}

	}

	.footer-bottom {
		padding:60px 0px 80px;
	}

	.footer-logos {
		img:first-child {
			margin-right: 40px;
		}
		p {
			max-width: 400px;
			margin-top: 20px;
			font-style: italic;
		}
		@media( max-width:$screen-sm ) {
		    padding: 30px;
		    text-align: center;

		    img {
		    	margin-bottom: 20px;
		    }

		}

	}

	.footer-contact {
		text-align: center;
		@media( min-width:$screen-sm-min ) {
			text-align: right;
    		float: right;
    	}

    	h2 {
    		margin-top: 0;
    		font-size: 14px;
    	}

    	ul {
    		list-style-type: none;
    	}
	}

}