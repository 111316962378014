@import "_vars";
@import "_mixins";

body {
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
	@include font-bold();
}
h2 {
	@include font-bolder();
}

.app-body {
	color: $black-color;
	background: url('../imgs/background-tile.png') repeat;
	//background-color: $white-color;
	overflow-x: hidden;
}

.container {
	padding-left: 0;
	padding-right: 0;
	@media( min-width:$screen-sm-min ) {
		padding-left: 15px;
		padding-right: 15px;
	}
}
