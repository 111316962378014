@import "_vars";

.app-header {
  // height: 107px;
  background-color: $white-color;

  @media (max-width: $screen-sm) {
    height: 112px;
  }
  @media (min-width: $screen-sm-min) {
  }

  > .container {
    padding: 10px 15px;
  }

  .header-left {
    h1,
    h2 {
      float: left;
    }

    h1 {
      margin: 0;
      a {
        position: relative;
        top: -4px;
        display: inline-block;
        width: 200px;
        height: 63px;
        /*position: absolute;
				top: 8px;
				left: 8px;
				@media( min-width:$screen-sm-min ) {
					left: 120px;
				}*/
        font-size: 0px;
        background: url("../imgs/logo.png") no-repeat;
        vertical-align: top;
      }
    }

    a {
      color: $yellow-color;
    }
    h2 {
      margin-left: 10px;
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 18px;
      @media (min-width: $screen-sm-min) {
        margin-left: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
        padding-left: 20px;
        border-left: 1px solid $yellow-color;
      }
      @media (max-width: $screen-sm) {
        display: none;
      }
    }
  }

  .header-right {
    @media (min-width: $screen-sm-min) {
      float: right;
    }

    h3 {
      font-size: 23px;
      @media (max-width: $screen-sm) {
        font-size: 14px;
        margin: 0px 10px 20px;
      }
    }
    h3,
    .search-wrapper {
      float: left;
    }
  }
  .search-wrapper {
    position: relative;
    min-width: 60px;
    margin-top: 14px;
    color: $black-color;

    input {
      display: none;
      width: 330px;
      padding: 5px 10px;
      color: $black-color;
      background-color: $yellow-color;
      border: 0;
      border-radius: 0;
      outline: 0;
      box-shadow: none;
      &::-webkit-input-placeholder {
        color: #fff;
      }
      &:-moz-placeholder {
        color: #fff;
      }
      &::-moz-placeholder {
        color: #fff;
      }
      &:-ms-input-placeholder {
        color: #fff;
      }
    }
    .fa {
      position: absolute;
      top: 8px;
      right: 10px;
      cursor: pointer;
      font-size: 16px;
    }
    .fa-spin,
    .fa-times {
      display: none;
      top: 9px;
    }
    .fa-search {
      font-size: 20px;
    }
    .search-results {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      overflow: auto;
      max-height: 250px;
      padding-top: 10px;
      padding-left: 0;
      list-style-type: none;
      border-top: 0;
      display: none;
      background-color: $yellow-color;

      ul {
        margin: 0;
        padding: 0;
      }
      li {
        padding: 0;
        cursor: pointer;
      }

      .category-search-results {
        margin-bottom: 10px;
        list-style-type: none;
        h3 {
          margin: 0;
          padding: 2px 12px;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }
        li {
          padding: 2px 24px;
          &.selected,
          &:hover {
            background-color: $white-color;
          }
        }
      }
    }
  }

  .menu {
    position: absolute;
    top: 10px;
    left: 10px;
    list-style-type: none;

    li {
      float: left;
      margin-top: 8px;
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid black;

      a {
        color: black;
      }

      &.active a,
      a:hover {
        text-decoration: underline;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  &.open {
    h3 {
      display: none;
    }
  }

  .app-header-menu {
    background-color: $green-color;
    border-bottom: 3px solid $yellow-color;

    .app-header-menu-list {
      display: flex;
      list-style-type: none;
      margin: 0;
      margin-left: -25px;
      padding: 0;
    }

    a {
      text-decoration: none;
    }

    .app-header-menu-list-item {
      position: relative;

      &:hover {
        background-color: $yellow-color;
        color: $black-color;

        .dropdown-content {
          display: block;
        }
      }

      a,
      button {
        display: inline-block;
        padding: 10px 25px;
        color: $white-color;
        cursor: pointer;
        text-transform: uppercase;
      }

      button {
        border: 0;
        background-color: transparent;
      }

      .dropdown-content {
        display: none;
        position: absolute;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        background-color: $green-color;

        a {
          display: block;

          &:hover {
            background-color: $yellow-color;
          }
        }
      }
    }
  }
}

/**
*	mobile menu
**/
.btn-navbar,
.nav-collapse {
  display: none;
}

@media (max-width: $screen-sm) {
  .btn-navbar {
    display: block;
    position: absolute;
    right: 5px;
    top: 15px;
  }
  .nav-collapse {
    display: none;
    position: absolute;
    top: 112px;
    width: 90%;
    z-index: 1;
    background-color: $green-color;

    a {
      color: $white-color;
    }

    .nav > li {
      ul {
        display: none;
        list-style-type: none;
      }
      &.active {
        background-color: $yellow-color;
        a {
          color: $black-color;
        }
      }
      li {
        padding: 10px 0;
        a.active {
          background-color: $yellow-color;
        }
      }
    }
  }

  .navbar-toggle .icon-bar {
    width: 25px;
    background-color: $black-color;
  }

  .nav > li a {
    display: block;
    padding: 20px 15px;
    text-decoration: none;
  }

  .nav > li a:focus,
  .nav > li a:hover {
    background-color: $yellow-color;
    color: $black-color;
  }

  .search-wrapper,
  .type-switch-navigation,
  .main-navigation,
  .map-legend {
    display: none;
  }
}
