@import "_vars";

.type-switch-navigation {
  position: absolute;
  top: 15px;
  right: 15px;

  ul {
    list-style-type: none;
  }

  li {
    float: left;
    margin-left: 10px;
    a {
      padding: 10px 25px;
      background-color: $green-color;
      color: $white-color;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
    }
    &.active a,
    a:hover,
    a:active {
      background-color: $yellow-color;
      color: $black-color;
      text-decoration: none;
    }
  }
}
