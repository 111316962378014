@import "_vars";

@mixin font-bold() {
	font-weight: 400;
}
@mixin font-bolder() {
	font-weight: 500;
}
@mixin golden-top() {
	border-top: 6px solid $yellow-color;
}
@mixin sort-arrow() {
	position: absolute;
	top: 4px;
	left: -7px;
	content: "\f0d8";
	font: normal normal normal 14px/1 FontAwesome;
}
@mixin desc-sort-arrow() {
	@include sort-arrow();
	content: "\f0d7";
}