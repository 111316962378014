.group-entity-data-table-wrapper {
	width: 100%;
	margin-bottom: 40px;

	.entity-data-table {
		width: 100%;
		text-align: right;

		th:first-child,
		td:first-child {
			width: 70%;
			text-align: left;
		}

	}
}
