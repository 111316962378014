@import "_vars";

/**
* GENERAL BAROMETER
**/

.barometer {

	position: relative;
	width: 100%;

	.divider {
		position: absolute;
		top: -5px;
		left: 50%;
		width: 1px;
		display: inline-block;
		padding: 5px 0 !important;
		border-right: 2px solid black;
		pointer-events: none;
	}

	.barometer-item {
		position: relative;
		float: left;
		padding: 0 !important;
		height: 100%;
		&.active {
			background-color: #000 !important;
			background-image: none !important;
		}

		.barometer-star {
			display: none;
			position: absolute;
			top: 12px;
			left: 19px;
			width: 12px;
			height: 12px;
			font-size: 18px;
			color: $white-color;
			&:hover {
				color: $black-color;
			}
		}	
		&.head {
			.barometer-star {
				display: block;
			}
		}
		
		&.barometer-item-women {
			background-color: $red-color;
		}
		&.barometer-item-men {
			background-color: $blue-color;
		}
		&.barometer-item-default {
			background-color: $gray-color;
		}

	}

}

/**
*	MAIN BAROMETER
**/
.main-barometer-wrapper {
	margin-top: 40px;

	.main-barometer {
		height: 55px;
		padding: 5px 0;
		.divider {
			height: 100%;
			top: 0;
		}

		.p-women-color, .p-men-color {
			background-size: 2px 2px;
		}
		.p-women-color {
			background-image: linear-gradient(90deg, $light-red-color, $red-color);
		}
		.p-men-color {
			background-image: linear-gradient(90deg, $blue-color, $dark-blue-color);
		}
		.divider {
			border-right: 3px solid black;
		}

	}

}
		
	.main-barometer-info {
		margin: 15px 0;
		text-transform: uppercase;
	}
		.main-barometer-title {
			font-size: 30px;
			font-weight: bold;
		}
		.main-barometer-value-label, .main-barometer-value-name {
			display: block;
			font-weight: bold;
		}
		.main-barometer-info-left,
		.main-barometer-info-right {
			font-size: 11px;
			@media( min-width:$screen-sm-min ) {
				font-size: 14px;
			}
		}
		.main-barometer-info-left {
			float: left;
			text-align: left;
			color: $red-color;
			
			.main-barometer-title {
				margin-right: 30px; 
			}
			.main-barometer-value {
				border-left: 1px solid $red-color;
			}
		}
		.main-barometer-info-right {
			float: right;
			text-align: left;
			color: $dark-blue-color;
			.main-barometer-title {
				margin-left: 10px; 
			}
			.main-barometer-value {
				border-left: 1px solid $dark-blue-color;
			}
		}
		.main-barometer-info-center {
			margin: 0 auto;
			margin-top: 30px;
			text-align: center;
			text-transform: uppercase;
			@media( min-width:$screen-sm-min ) {
			    margin-top: -80px;
			}
			.main-barometer-value {
				float: none;
				@media( min-width:$screen-sm-min ) {
					float: right;
				}
			}
			.main-barometer-value-label {
				font-size: 56px;
			}
		}
		@media( min-width:$screen-xs-min ) {
			.main-barometer-value, .main-barometer-title {
				float: left;
			}
		}
		.main-barometer-value {
			margin-right: 20px;
			padding-left: 10px;
			&:last-child {
				margin-right: 0px;
			}
		}
			.main-barometer-value-label {
				font-size: 30px;
				@media( min-width:$screen-sm-min ) {
					font-size: 24px;
				}
				font-weight: bold;
			}
			.main-barometer-value-name {
				font-size: 12px;
			}
			

.main-barometer-tooltip, .main-barometer-index-tooltip {
	position: absolute;
	top: -2px;
	color: #fff;
	background-color: #000;
	transform: translate(0,-100%);
	-webkit-transform: translate(0,-100%);
	-ms-transform: translate(0,-100%);

	&:after {
		content: " ";
		position: absolute;
		top: 100%;
		left: 50%;
		border: 6px solid transparent;
		border-top: 6px solid black;
		-webkit-transform: translate(-6px,0);
		-ms-transform: translate(-6px,0);
		transform: translate(-6px,0);
	}
	&.reverse:after {
		left: 100%;
		border-right: 6px solid black;
		border-left: 0;
		-webkit-transform: translate(-6px,0);
		-ms-transform: translate(-6px,0);
		transform: translate(-6px,0);
	}
	h3 {
		margin: 0;
		margin-bottom: -5px;
    	font-size: 12px;
		padding: 10px 10px 0px;
    	font-weight: 500;
		color: $yellow-color;
		text-transform: uppercase;
		text-align: center;
	}
	p {
		margin: 0;
		padding: 5px 10px;
		white-space: nowrap;
		font-weight: bold;
	}
}
.main-barometer-tooltip {
	/* hidden by default */
	display: none;
}
.main-barometer-index-tooltip {
	transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
}

.compare-main-barometer .main-barometer-index-tooltip {
	p {
		display: none;
	}
}