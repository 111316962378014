@import "_mixins";

.app-page-wrapper {
  @include golden-top();
	padding: 20px 20px 80px;
	
	> .container {
		max-width: 680px;
	}
  
  h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    margin: 10px 0 15px;
  }

	h1, h2 {
		margin: 20px 0px 30px;
  }

  p {
		line-height: 1.4;
		margin: 10px 0;
		text-align: justify;
  }
  
  ul, ol {
    padding-left: 15px;
  }

	.pull-left {
		width: 50%;
		&:last-child {
			padding-left: 30px;
		}
	}

	img {
		max-width: 80%;
    	height: auto;
	}
}