@import "_mixins";

.app-about-wrapper {

	@include golden-top();
	padding: 20px 20px 80px;
	
	> .container {
		max-width: 680px;
	}
	
	h2 {
		text-transform: uppercase;
		margin: 20px 0px 30px;
	}

	p {
		line-height: 1.4;
		margin: 10px 0;
		text-align: justify;
	}

	.pull-left {
		width: 50%;
		&:last-child {
			padding-left: 30px;
		}
	}

	img {
		max-width: 80%;
    	height: auto;
	}
}