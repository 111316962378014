@import "_vars";

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

.dr-women-color {
	background-color: $red-color;
}
.p-women-color {
	background-color: $light-red-color;
}
.dr-men-color {
	background-color: $dark-blue-color;
}
.p-men-color {
	background-color: $blue-color;
}

.index-color-1 {
	background-color: #f06868;
}
.index-color-2 {
	background-color: #f59c9c;
}
.index-color-3 {
	background-color: #facdcd;
}
.index-color-4 {
	background-color: #ffffff;
}
.index-color-5 {
	background-color: #cbe6fa;
}
.index-color-6 {
	background-color: #97ccf5;
}
.index-color-7 {
	background-color: #61b2f0;
}

.error-label {
	color: #ff0000;
}
.form-control.error {
	border: 1px solid red;
}

.back-link {
	position: relative;
    top: -14px;
    @media( max-width:$screen-sm ) {
    	left: -20px;
    }
    padding: 10px 20px;
    color: $black-color;
    background-color: $yellow-color;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    &:hover {
    	color: $white-color;
    	background-color: $green-color;
    	text-decoration: none;
    }
    i {
    	margin-right: 7px;
    }
}

.question-mark {
	position: relative;
	margin-left: -3px;
    padding: 5px;
    padding-right: 0;
	cursor: pointer;
}

.table-total {
	.fa { 
		margin-right: 10px;
	}
}

.app-tooltip {
	position: absolute;
	pointer-events: none;
	color: #fff;
	background-color: #000;
	transform: translate(0,-100%);
	-webkit-transform: translate(0,-100%);
	-ms-transform: translate(0,-100%);

	/* hidden by default */
	display: none;

	&:after {
		content: " ";
		position: absolute;
		top: 50%;
		left: -12px;
		/* left: 50%; */
		border: 6px solid transparent;
		border-right: 6px solid black;
		-webkit-transform: translate(-6px, 0);
		-ms-transform: translate(-6px,0);
		transform: translate(0, -6px);
	}
	&.reverse:after {
		left: 100%;
		top: 50%;
		border-left: 6px solid black;
		border-right: 0;
	}
	p {
		margin: 0;
		padding: 5px 10px;
		white-space: nowrap;
	}
}