@import "_vars";
@import "_mixins";

/**
*	APP DETAIL
**/
.app-detail-wrapper {

	@include golden-top();
	padding: 20px 20px 80px;

	> .container {
		position: relative;
	}

	.entity-detail-info-wrapper {
		margin-top: 0px;
		font-size: 14px;
		
		span {
			padding: 0 10px;
			border-right: 1px solid #666;
			&:first-child { 
				padding-left: 0px;
			}
			&:last-child {
				border-right: 0;
			}
			i {
				margin-right: 8px;
			}
		}

		a {
			color: inherit;
			text-decoration: underline;
		}

	}

	thead {
		border-bottom: 1px solid #666;
		th {
			padding-bottom: 5px;
		}
	}

	/*tr:first-child {
		td {
			padding-top: 5px;
		}
	}*/

}

.entity-header-wrapper {}
.entity-header-wrapper-right {

	@media( min-width:$screen-sm ) {
		.entity-header-wrapper-title {
			float: right;
		}
		.main-barometer-info-center {
			margin-top: 3px;
		}
		.main-barometer-info-center .main-barometer-value, 
		.main-barometer-info-center .main-barometer-value .main-barometer-value-name {
			float: left;
		}
	}

}

.col-entity-compare {

	.col-entity-compare-barometer-header {
		margin-top: 30px;
		margin-bottom: -35px;

		.main-barometer-title {
			font-size: 18px;
		}
		.main-barometer-title-men {
			color: $blue-color;
		}
		.main-barometer-title-women {
			color: $red-color;
		}
	}

	.entity-header-wrapper-title {
		width: 70%;
	}

	h2, .entity-detail-info-wrapper {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.group-entity-data-table-wrapper {
		margin-top: 20px;
	}

	.group-entity-data-table-wrapper .entity-data-table th:first-child, 
	.group-entity-data-table-wrapper .entity-data-table td:first-child {
		width: 55%;
	}

}

.close-compare-btn {
	display: inline-block;
	top: -20px;
	left: 50%;
	-ms-transform: translate(-50%,0);
	-webkit-transform: translate(-50%,0);
	transform: translate(-50%,0);
}

.compare-select-wrapper {
	position: absolute;
    top: -20px;
	left: 50%;
	@media( max-width:$screen-sm ) {
		left: 78%;
	}
	-ms-transform: translate(-50%,0);
	-webkit-transform: translate(-50%,0);
	transform: translate(-50%,0);
	width: 150px;
	overflow: hidden;
	background-color: $yellow-color;
	color: $black-color;
	cursor: pointer;
	&:hover {
		color: $white-color;
		background-color: $green-color;
	}
	&:after {
		content:"\f0ae";
		display: inline-block;
		position: absolute;
		top: 8px;
		left: 14px;
		width: 20px;
		height: 20px;
		font: normal normal 14px/1 FontAwesome;
	}
	select {
		width: 200px;
		padding: 11px 20px 11px 50px;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 12px;
		line-height: 1;
		border: 0;
		outline: 0;
		border-radius: 0;
		background: none;
		-moz-appearance: none;
		-webkit-appearance: none;

		option {
			color: #000000;
		}
	}
}

/**
*	entity-data-table
**/
.entity-data-table-wrapper,
.entity-grid-wrapper {
	
}
.entity-data-table-wrapper {
	padding-left: 0px;
}

.entity-grid-wrapper {
	padding-right: 0px;
	text-align: center;
	@media( max-width:$screen-sm ) {
		margin-top: 40px;
		padding-left: 0px;
    }

	h3 {
		margin: 0;
		margin-bottom: 10px;
		padding-left: 10px;
		padding-bottom: 8px;
		font-size: 14px;
		font-weight: bold;
		border-bottom: 1px solid black;
	}
	.entity-grid-inner {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: flex-start;
		max-height: 130px;
		padding-right: 20px;
		padding-left: 10px;
	}
	.entity-grid  {
		float: left;
		width: 50%;
		margin-top: 12px;
		text-align: left;
		cursor: pointer;
	}
		.entity-grid-cell {
			position: relative;
			display: inline-block;
			width: 25px;
			height: 25px;
			margin: 0 2px 2px 0;
			&.head:before {
				content: "\f005";
				position: absolute;
				top: 5px;
				left: 5px;
				display: inline-block;
				width: 12px;
				height: 12px;
				font: normal normal normal 14px/1 FontAwesome;
				font-size: 15px;
				color: $white-color;
			}
			&.highlight {
				background-color: #000;
			}
		}

}

.entity-data-table {

	table {
		width: 100%;
		table-layout: fixed;

		td:last-child {
			text-align: right;
		}
	}

	td, th {
		padding: 10px 5px;
		@media( min-width:$screen-sm-min ) {
			padding: 10px 15px;
		}
		text-align: right;
		&:first-child {
			text-align: left;
		}
	}
	td {
		border: 0px;
		border-top: 1px solid black;
		border-bottom: 1px solid black;
	}

	tbody tr:first-child td:first-child:before,
	tbody tr:nth-child(2) td:first-child:before {
		position: relative;
		top: 3px;
		content: " ";
		display: inline-block;
		width: 4px;
		height: 15px;
		margin: 0 10px 0 -15px;
	}
	tbody tr:first-child td:first-child:before {
		background-color: $red-color;
	}
	tbody tr:nth-child(2) td:first-child:before {
		background-color: $blue-color;
	}

}