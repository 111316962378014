@import "_vars";

.map-wrapper {
	
	position: relative;

	.map-popup {
		
		display: none;

		position: absolute;
		max-width: 300px;
		min-width: 200px;
		padding: 5px 10px;
		background-color: #fff;
		z-index: 1;
		pointer-events: none;
	
		h2 {
			margin-top: 10px;
			font-size: 20px;
			font-weight: bold;
		}

		.map-popup-index {
			font-size: 18px;
			font-weight: bold;
		}
		.map-popup-size {
			float: right;
		}
		.map-popup-footer {
			height: 28px;
			margin-left: -10px;
			margin-right: -10px;
			margin-bottom: 2px;

			.barometer,
			.barometer-item {
				height: 28px;
			}

			.divider {
				height: auto;
				top: -7px;
				bottom: -22px;
				z-index: 1;
			}

		}

		&:after {
			content: " ";
			height: 15px;
			position: absolute;
			width: 15px;
			top: 100%;
			-webkit-transform: translate(-50%,0);
			-ms-transform: translate(-50%,0);
			transform: translate(-50%,0);
			left: 50%;
			border: 15px solid transparent;
			border-top: 15px solid $white-color;
		}

	}

	.map-legend {

		position: absolute;
		bottom: 35px;
		left: 15px;

		li {
			display:inline-block;
			float: left;
			width: 25px;
			height: 16px;
			margin-right: 0px;
			cursor: pointer;
		}
	}

	.map-menu-wrapper {
		@media( min-width:$screen-sm-min ) {
			.gmnoprint, .gmnoscreen, .gmnoprint.gm-style-cc {
				bottom: 40px !important;
			}
		}
	}
	
}

.app-map-wrapper {
	width: 100%;
	height: 200px;
	background-color: #bbbbbb;
	@media( min-width:$screen-sm-min ) {
		height: 500px;
	}
}
	.app-detail-map-wrapper {
		height: 150px;
		@media( min-width:$screen-sm-min ) {
			height: 300px;
		}
	}

.map-legend-tooltip {
	position: absolute;
	color: #fff;
	background-color: #000;
	transform: translate(0,-100%);
	-webkit-transform: translate(0,-100%);
	-ms-transform: translate(0,-100%);

	/* hidden by default */
	display: none;

	&:after {
		content: " ";
		position: absolute;
		top: 30px;
		left: 50%;
		border: 6px solid transparent;
		border-top: 6px solid black;
		-webkit-transform: translate(-6px,0);
		-ms-transform: translate(-6px,0);
		transform: translate(-6px,0);
	}
	&.reverse:after {
		left: 100%;
		border-right: 6px solid black;
		border-left: 0;
		-webkit-transform: translate(-6px,0);
		-ms-transform: translate(-6px,0);
		transform: translate(-6px,0);
	}
	p {
		margin: 0;
		padding: 5px 10px;
		white-space: nowrap;
	}
}
