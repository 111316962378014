@import "_vars";

ul {
	padding-left: 0;
}
.no-bullets {
	list-style-type: none;
}

.content-wrapper {
	padding: 20px;
}
	.content-wrapper h2 {
		margin-top: 0px;
	}

	@media (min-width: 768px) {
		.edit-form .form-group {
			width: 50%;
		}	
	}
	

.back-btn {}
	.back-btn i {
		margin-bottom: 10px;
		padding-right: 5px;
	}

.flash {
	margin-bottom: 15px;
	padding: 10px;
}
	.flash p {
		margin: 0;
	}

.edit-btn {}
	.edit-btn i {
		margin-right: 5px;
	}

.admin-map {
	width: 100%;
	height: 250px;
	margin-bottom: 10px;
}

.person-grid {

	tbody a {
		color: black;
		text-decoration: underline;
	}
	.men-row {
		background-color: $blue-color;
	}
	.women-row {
		background-color: $light-red-color;
	}

}

/**
*	ENTITY MODULE
**/
.entity-show-module {
	.entity-section {
		padding: 10px 0;
		border-top: 1px solid black;
		&:first-child {
			padding-top: 0;
			border-top: 0;
		}
	}
	.entity-people-section {
		.btn-toolbar {
			display: none;
		}
	}
	h4 {
		text-transform: uppercase;
	}
	.time-select-section {
		h4 {
			margin-bottom: -20px;
		}
		form {
			margin-bottom: 20px;
		}
	}
}

/**
*	DIFF MODULE
**/
.diff-table {
	table {
		width: 50%;
	}
	tr {
		border-bottom: 1px solid black;
		td, th {
			padding: 10px;
			text-align: center;
			&:first-child {
				text-align: left;
			}
		}

		a {
			text-decoration: underline;
		}

		&.bg-red a, &.bg-green a{
			color: white;
		}
	}
}
