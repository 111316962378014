@import "_vars";

.main-navigation {
	
	position: relative;
	z-index: 1;
	float: right;
	margin-top: -40px;
	margin-right: -10px;

	ul {
		padding-left: 0px;
		list-style-type: none;
	}

	li {
		float: left;
		margin-right: 10px;
		padding: 0 10px;
		background-color: $green-color;
		a {
			display: inline-block;
			padding: 10px 20px;
			text-transform: uppercase;
			color: $white-color;
			font-weight: 500;
		}
		&.active,
		&.active a,
		&:hover,
		&:hover a {
			color: $black-color;
			background-color: $yellow-color;
			text-decoration: none;
		}

	}
}